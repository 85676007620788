<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.invite_code" placeholder="邀请码"></el-input>
          </el-col>
          <el-col :span="12">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
              <el-button @click="handsRegisterBox = true" type="primary">注册用户</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column  prop="nickname" label="昵称" width="200" show-overflow-tooltip>
          <template #default="s">
            <div class="flex-def">
              <img v-if="s.row.avatar" :src="s.row.avatar | tomedia" style="width: 3rem;height: 3rem;border-radius: 2rem" alt="">
              <div style="margin-left: .5rem">
                <div>{{s.row.nickname}}</div>
                <div>{{s.row.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  prop="invite_code" label="邀请码"></el-table-column>
        <el-table-column  prop="from_invite_code" width="110" label="注册使用邀请码"></el-table-column>
        <el-table-column  prop="from_uid" label="注册邀请人">
          <template #default="s">
            <div v-if="s.row.from_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.from_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.from_user.nickname }}</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="head_uid" width="110" label="所属团队长编号" :formatter="e=>e.head_uid > 0 ? e.head_uid : ''"></el-table-column>
<!--        <el-table-column prop="first_head_uid" width="110" label="第一团队长编号" :formatter="e=>e.first_head_uid > 0 ? e.first_head_uid : ''"></el-table-column>-->
        <el-table-column  prop="level" :formatter="levelFormat" label="等级"></el-table-column>
        <el-table-column  prop="balance" label="余额"></el-table-column>
        <el-table-column  prop="bonus" label="佣金"></el-table-column>
        <el-table-column  prop="total_cash" label="累计提现"></el-table-column>
        <el-table-column  prop="help_fee" label="帮扶金"></el-table-column>
        <el-table-column  prop="cost" label="消费账户"></el-table-column>
        <el-table-column  prop="total_order" label="成交订单"></el-table-column>
        <el-table-column  prop="total_order_fee" label="成交总额"></el-table-column>

        <el-table-column width="100" label="操作">
          <template v-slot="s">
            <YUserDetail :uid="s.row.id">
              <span class="y-pointer">详情</span>
            </YUserDetail>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
      <el-dialog title="注册用户" width="20rem" :visible.sync="handsRegisterBox" @closed="handsRegisterClosed">
        <el-form v-loading="registerLoading" :model="handsRegisterForm">
          <el-form-item label="邀请码">
            <el-input v-model="handsRegisterForm.invite_code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="handsRegisterForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="用户等级">
            <el-select v-model="handsRegisterForm.level" placeholder="请选择">
              <el-option
                  v-for="item in showLevelOptions"
                  :key="item.level"
                  :label="item.title"
                  :value="item.level">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登陆密码">
            <el-input v-model="handsRegisterForm.password" autocomplete="off"></el-input>
            <span class="y-desc">为空时默认填充为 123456</span>
          </el-form-item>
          <el-form-item label="用户昵称">
            <el-input v-model="handsRegisterForm.nickname" autocomplete="off"></el-input>
            <span class="y-desc">为空时默认填充为 用户昵称</span>
          </el-form-item>
          <el-form-item label="用户头像">
            <y_upload_img v-model="handsRegisterForm.avatar"></y_upload_img>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handsRegisterBox = false">取 消</el-button>
          <el-button type="primary" @click="handsRegister">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

// import y_member_up_level from "@/components/y_member_up_level";
// import y_member_invite_change from "@/components/y_member_invite_change";
// import y_member_cash_account from "@/components/y_member_cash_account";

import {userLevel2Tag,levelOption} from "@/common/mod/user";
import YUserDetail from "@/components/shop/YUserDetail";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "manage",
  components: {
    YUserDetail,
    y_upload_img,
    // y_member_up_level,y_member_invite_change,
    // y_member_cash_account
  },

  data() {
    return {
      registerLoading:false,
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
        invite_code:"",
      },
      handsRegisterBox:false,
      handsRegisterForm:{
        avatar:"",
        phone :"",
        password :"",
        nickname :"",
        invite_code:"",
        level:1,
      },
    }
  },
  computed:{
    showLevelOptions(){
      return levelOption;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    levelFormat(s){
      return  userLevel2Tag(s.level)
    },
    handsRegisterClosed(){
      this.handsRegisterForm = {
        avatar:"",
        phone :"",
        password :"",
        nickname :"",
        invite_code:"",
        level:1,
      }
    },
    handsRegister(){
      if(this.registerLoading)return;
      this.registerLoading = true;
      this.$u.api.user.handsRegister(this.handsRegisterForm).then(()=>{
        this.handsRegisterBox = false;
        this.$message.success("操作成功");
        this.$nextTick(()=>{
          this.registerLoading = false;
        })
        this.searchGo();
      }).catch(()=>{
        this.registerLoading = false;
      })
    },
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
        invite_code:"",
      }
      this.searchGo()
    },
    load() {
      this.$u.api.user.search({
        page:this.page,
        nickname:this.searchForm.nickname,
        phone:this.searchForm.phone,
        invite_code:this.searchForm.invite_code,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>