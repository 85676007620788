<template>
  <div>
    <div @click="detailShow">
      <slot></slot>
    </div>
    <el-drawer
        size="40rem"
        destroy-on-close
        modal-append-to-body
        title="用户详情"
        :visible.sync="detailDrawer"
        :before-close="detailClose">
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          基本信息
        </div>
        <div class="flex-def flex-cCenter" style="margin-top: .5rem;width: 100%;font-size: .8rem">
          <img :src="info.avatar | tomedia"
               style="width: 5rem;height: 5rem;border-radius: 10px;background-color: #f8f8f8">
          <div style="margin-left: 1rem;width: 100%">
            <div class="flex-def">
              <div>
                <div>{{ info.nickname }}</div>
                <div>{{ info.phone }}</div>
              </div>
              <div style="margin-left: 1rem">
                <div>用户ID:{{ info.id }}</div>
                <div>邀请码：{{ info.invite_code }}</div>
              </div>
            </div>
            <div style="margin-top: .5rem;width: 100%" class="flex-def flex-zBetween flex-cCenter">
              <div>注册时间：{{ info.created_at | date }}</div>
              <!--              <div class="red-btn">加入黑名单</div>-->
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          实名信息
        </div>
        <div class="flex-def flex-cCenter">
          <div v-if="info.ac_name && info.ac_id_card ">
            <div>真实姓名：{{info.ac_name}}</div>
            <div>身份证号：{{info.ac_id_card}}</div>
            <div>认证时间：{{info.ac_time | date}}</div>
          </div>
          <div v-else>未认证</div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="flex-def">
          <div @click="rechargeBox=true" class="red-btn y-pointer" style="background-color: #f08b2c">充值</div>
        </div>
        <div class="flex-def flex-cCenter flex-zBetween"
             style="margin-top: 1rem;width: 100%;font-size: .8rem;font-weight: 600">
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ info.balance }}</div>
            <div style="margin-top: .5rem">账户余额</div>
          </div>
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ info.total_cash }}</div>
            <div style="margin-top: .5rem">累计提现</div>
          </div>
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ info.help_fee }}</div>
            <div style="margin-top: .5rem">帮扶基金</div>
          </div>
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ info.cost }}</div>
            <div style="margin-top: .5rem">消费账户</div>
          </div>
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ info.total_order }}</div>
            <div style="margin-top: .5rem">成交订单</div>
          </div>
          <div class="desc-item flex-def flex-zTopBottom flex-zCenter flex-cCenter">
            <div>{{ info.total_order_fee }}</div>
            <div style="margin-top: .5rem">成交总额</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          团队信息
        </div>
        <div class="flex-def flex-cCenter flex-zBetween"
             style="margin-top: 1rem;width: 100%;font-size: .8rem;font-weight: 600">
          <div @click="inviteLinkShow" class="team-item y-pointer">他的用户</div>
          <div @click="teamShow" class="team-item y-pointer">所属团队</div>
          <div @click="cashLogShow" class="team-item y-pointer">提现记录</div>
          <div @click="balanceLogShow" class="team-item y-pointer">余额明细</div>
          <div @click="awardLogShow" class="team-item y-pointer">佣金明细</div>
          <div @click="help_u_show=true" class="team-item y-pointer">帮扶用户</div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          用户等级信息
        </div>
        <div style="margin-top: 1rem;font-size: .8rem">
          <div class="flex-def flex-cCenter">
            <div>用户等级</div>
            <div style="margin-left: 1rem;background-color: #d1cde6;padding: .2rem 1rem;border-radius: 10px">
              {{ userLevel2Tag(info.level) }}
            </div>
            <div @click="levelBox=true" class="y-pointer" style="margin-left: 1rem;color: red">修改等级</div>
          </div>
          <div class="flex-def flex-cCenter" style="margin-top: .5rem">
            <div>区域代理</div>
            <div style="margin-left: 1rem;background-color: #d1cde6;padding: .2rem 1rem;border-radius: 10px">
              {{ areaAgentLevelFormat }}
            </div>
            <div class="y-pointer" @click="areaAgentBox=true" style="margin-left: 1rem;color: red">修改等级</div>
          </div>
          <div class="flex-def flex-cCenter" style="margin-top: .5rem">
            <div>股东信息</div>
            <div style="margin-left: 1rem;">{{ info.shareholder ? '是' : '否' }}</div>
          </div>
          <div class="flex-def flex-cCenter" style="margin-top: .5rem">
            <div>市场总监</div>
            <div style="margin-left: 1rem;">{{ info.cmo ? '是' : '否' }}</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          邀请人信息
        </div>
        <div class="flex-def flex-cCenter">
          <div v-if="from_user.id > 0">
            <div class="flex-def flex-cCenter" style="margin-top: 1rem;font-size: .8rem">
              <img :src="from_user.avatar | tomedia" style="border-radius: 2rem;height: 2rem;width: 2rem;">
              <div style="margin-left: 1rem">
                <div>{{ from_user.nickname }} <span style="color: red">（ID:{{ from_user.id }}）</span></div>
                <div>{{ from_user.phone }}</div>
              </div>
            </div>
            <div style="margin-top: 1rem;">
              <div>注册使用邀请码：{{ info.from_invite_code }}</div>
              <div>邀请人等级：{{ from_user.level }}</div>
            </div>
          </div>
          <y_choose_member @change="chooseInvite">
            <div class="y-pointer" style="color: red;margin-left: 1rem">修改邀请人</div>
          </y_choose_member>
          <div v-if="from_user.id > 0" @click="clearInvite" class="y-pointer" style="color: red;margin-left: 1rem">
            清空邀请人
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title flex-def flex-cCenter">
          <div>公众号关注状态</div>
          <div style="margin-left: 1rem" class="red-btn">{{ follow ? '已关注' : '未关注' }}</div>
        </div>
        <div style="margin-top: .5rem">公众号openid <span style="margin-left: 1rem">{{ official.openid }}</span></div>
        <div style="margin-top: .5rem">小程序openid <span style="margin-left: 1rem">{{ mini.openid }}</span></div>
      </div>
      <el-divider></el-divider>
      <div class="section-box">
        <div class="section-title">
          <div class="title-tag"></div>
          账户信息
        </div>
        <div class="flex-def flex-zBetween" style="margin-top: 1rem">
          <div>
            <div>支付宝姓名：{{ ali_pay.name }}</div>
            <div>支付宝账户：{{ ali_pay.account }}</div>
          </div>
          <div>
            <div>银行卡姓名：{{ bank.name }}</div>
            <div>银行卡账户：{{ bank.account }}</div>
            <div>开户行：{{ bank.bank_name }}</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="section-box" style="padding-bottom: 2rem">
        <div class="section-title">
          <div class="title-tag"></div>
          用户地址
        </div>
        <div v-for="(item,key) in address" :key="key" style="margin-top: 1rem">
          <div>收货地址：{{ item.full }}</div>
          <div>联系人：{{ item.name }}</div>
          <div>手机：{{ item.phone }}</div>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="充值" width="20rem" :visible.sync="rechargeBox" @closed="rechargeClosed">
      <el-form>
        <el-form-item label="充值金额">
          <el-input-number v-model="rechargeForm.fee" autocomplete="off"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rechargeBox = false">取 消</el-button>
        <el-button type="primary" @click="rechargeSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="他的用户" :visible.sync="invite_link_show" destroy-on-close>
      <el-table :data="invite_link_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column :formatter="e=>e.from_uid === uid ? '直推' : '关系链'" label="邀请关系"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="level" label="等级"></el-table-column>
        <el-table-column prop="from_uid" label="注册邀请人编号"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="invite_link_total" :page-size="15" :current-page="invite_link_page"
                         @current-change="invite_link_page_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="所属团队" :visible.sync="team_show" destroy-on-close>
      <el-table :data="team_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column :formatter="e=>e.head_uid === 0 ? '团队长' : '成员'" label="团队关系"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="level" label="等级"></el-table-column>
        <el-table-column prop="from_uid" label="注册邀请人编号"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="team_total" :page-size="15" :current-page="team_page" @current-change="team_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="提现记录" :visible.sync="cash_log_show" destroy-on-close>
      <el-table :data="cash_log_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="fee" label="提现金额"></el-table-column>
        <el-table-column prop="rate_fee" label="手续费"></el-table-column>
        <el-table-column prop="real_fee" label="实际到账"></el-table-column>
        <el-table-column prop="status" :formatter="e=>e.status === 2 ? '已打款' :  e.status === 1? '待打款' : '已驳回'"
                         label="提现状态"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="cash_log_total" :page-size="15" :current-page="cash_log_page"
                         @current-change="cash_log_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="余额明细" :visible.sync="balance_log_show" destroy-on-close>
      <el-table :data="balance_log_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="fee" label="变动金额"></el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="balance_log_total" :page-size="15" :current-page="balance_log_page"
                         @current-change="balance_log_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="佣金明细" :visible.sync="award_log_show" destroy-on-close>
      <div class="flex-def flex-cCenter" style="width: 100%;padding: .5rem">
        <el-date-picker v-model="award_log_filter_date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-button-group>
          <el-button @click="awardLogSearch">搜索</el-button>
          <el-button @click="awardLogSearchReset">重置</el-button>
        </el-button-group>
      </div>
      <el-table :data="award_log_list">
        <el-table-column prop="id" width="80" label="编号"></el-table-column>
        <el-table-column prop="source_user.id" width="100" label="来源用户编号"></el-table-column>
        <el-table-column prop="source_user.nickname" label="来源用户昵称"></el-table-column>
        <el-table-column prop="fee" label="金额"></el-table-column>
        <el-table-column label="日期" width="180" show-overflow-tooltip>
          <template v-slot="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <el-table-column prop="order_id" label="关联订单编号"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="award_log_total" :page-size="10" :current-page="award_log_page"
                         @current-change="award_log_change">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog title="帮扶用户" :visible.sync="help_u_show" destroy-on-close>
      <div v-for="(item,key) in help_user" :key="key">
        <div class="flex-def">
          <div class="flex-def flex-cCenter" style="font-size: .8rem">
            <img :src="item.avatar | tomedia" style="border-radius: 2rem;height: 2rem;width: 2rem;">
            <div style="margin-left: 1rem">
              <div>{{ item.nickname }} <span style="color: red">（ID:{{ item.id }}）</span></div>
              <div>{{ item.phone }}</div>
            </div>
          </div>
          <div style="margin-left: 1rem;">
            <div>邀请码：{{ item.invite_code }}</div>
            <div>等级：{{ userLevel2Tag(item.level) }}</div>
          </div>
        </div>
        <el-divider v-if="key == 0"></el-divider>
      </div>

    </el-dialog>
    <el-dialog title="修改用户等级" width="20rem" :visible.sync="levelBox" destroy-on-close>
      <el-form>
        <el-form-item label="变更等级">
          <el-radio-group v-model="level_form.level">
            <el-radio v-for="(item,key) in levelOption" :key="key" :label="item.level">{{ item.title }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="levelBox = false">取 消</el-button>
        <el-button type="primary" @click="levelSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog append-to-body title="修改区域代理等级" :visible.sync="areaAgentBox" destroy-on-close>
      <div>
        <el-form label-width="70px">
          <el-form-item label="等级">
            <el-radio-group v-model="areaAgentForm.level">
              <el-radio :label="1">乡镇</el-radio>
              <el-radio :label="2">区县</el-radio>
              <el-radio :label="3">市</el-radio>
              <el-radio :label="4">省</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="areaAgentBox = false">取 消</el-button>
        <el-button type="primary" @click="areaAgentSubmit">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {levelOption, userLevel2Tag} from "@/common/mod/user";
import y_choose_member from "@/components/shop/y_choose_member";

export default {
  name: "YUserDetail",
  components: {y_choose_member},
  props: {
    uid: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      detailDrawer: false,
      info: {},
      from_user: {},
      follow: false,
      official: {},
      mini: {},
      ali_pay: {},
      bank: {},
      address: [],
      help_user: [],

      rechargeBox: false,
      rechargeForm: {
        fee: 0,
      },

      invite_link_list: [],
      invite_link_page: 0,
      invite_link_total: 0,
      invite_link_show: false,

      team_list: [],
      team_page: 0,
      team_total: 0,
      team_show: false,

      cash_log_list: [],
      cash_log_page: 0,
      cash_log_total: 0,
      cash_log_show: false,
      award_log_search_date: [],
      award_log_filter_date: [],

      balance_log_list: [],
      balance_log_page: 0,
      balance_log_total: 0,
      balance_log_show: false,

      award_log_list: [],
      award_log_page: 0,
      award_log_total: 0,
      award_log_show: false,

      help_u_show: false,

      levelOption: levelOption,
      levelBox: false,
      level_form: {
        level: 1,
      },

      areaAgentBox: false,
      areaAgentForm: {
        level: 1,
      }
    }
  },
  computed: {
    areaAgentLevelFormat() {
      let str = "";
      switch (parseInt(this.info.area_agent)) {
        case 1:
          str = "乡镇";
          break;
        case 2:
          str = "区县";
          break;
        case 3:
          str = "市";
          break;
        case 4:
          str = "省";
          break;
        default:
          str = "无"
          break;
      }
      return str;
    },
  },
  methods: {
    clearInvite() {
      this.$u.api.user.selInviteU({uid: this.uid, invite_u: 0}).then(() => {
        this.$message.success("操作成功");
        this.detailShow();
      })
    },
    chooseInvite({id}) {
      this.$u.api.user.selInviteU({uid: this.uid, invite_u: id}).then(() => {
        this.$message.success("操作成功");
        this.detailShow();
      })
    },
    areaAgentSubmit() {
      this.$u.api.shop.plugin.areaAgent.userSetLevel({uid: this.uid, level: this.areaAgentForm.level}).then(() => {
        this.$message.success("操作成功");
        this.areaAgentBox = false;
        this.detailShow();
      })
    },
    levelSubmit() {
      this.$u.api.user.setLevel({uid: this.uid, level: this.level_form.level}).then(() => {
        this.$message.success("操作成功");
        this.levelBox = false;
        this.detailShow();
      })
    },
    userLevel2Tag(level) {
      return userLevel2Tag(level);
    },
    award_log_change(e) {
      this.award_log_page = e;
      this.awardLogShow();
    },
    awardLogSearchReset(){
      this.award_log_filter_date = [];
      this.awardLogSearch();
    },
    awardLogSearch(){
      this.award_log_search_date = this.award_log_filter_date;
      this.award_log_list = [];
      this.award_log_total = 0;
      this.award_log_change(1);
    },
    awardLogShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.awardLog({
        page: this.award_log_page,
        id: this.uid,
        range:this.award_log_search_date,
      }).then(res => {
        this.award_log_list = res.list;
        this.award_log_total = res.total;
        this.award_log_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    balance_log_change(e) {
      this.balance_log_page = e;
      this.balanceLogShow();
    },
    balanceLogShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.balanceLog({page: this.balance_log_page, id: this.uid}).then(res => {
        this.balance_log_list = res.list;
        this.balance_log_total = res.total;
        this.balance_log_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    cash_log_change(e) {
      this.cash_log_page = e;
      this.cashLogShow();
    },
    cashLogShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.cashLog({page: this.cash_log_page, id: this.uid}).then(res => {
        this.cash_log_list = res.list;
        this.cash_log_total = res.total;
        this.cash_log_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    team_change(e) {
      this.invite_link_page = e;
      this.teamShow();
    },
    teamShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.team({page: this.team_page, id: this.uid}).then(res => {
        this.team_list = res.list;
        this.team_total = res.total;
        this.team_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    invite_link_page_change(e) {
      this.invite_link_page = e;
      this.inviteLinkShow();
    },
    inviteLinkShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.inviteLinkSearch({page: this.invite_link_page, id: this.uid}).then(res => {
        this.invite_link_list = res.list;
        this.invite_link_total = res.total;
        this.invite_link_show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    rechargeClosed() {
      this.rechargeForm = {
        fee: 0,
      }
    },
    rechargeSubmit() {
      this.$u.api.user.balanceRecharge({uid: this.uid, fee: this.rechargeForm.fee}).then(() => {
        this.$message.success("操作成功");
        this.detailShow();
        this.rechargeBox = false;
      })
    },
    detailShow() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.user.detail({id: this.uid}).then(res => {
        this.info = res.info;
        this.from_user = res.from_user;
        this.follow = res.follow;
        this.official = res.official;
        this.mini = res.mini;
        this.ali_pay = res.ali_pay;
        this.bank = res.bank;
        this.address = res.address;
        this.help_user = res.help_user;
        this.detailDrawer = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    },
    detailClose(done) {
      done();
    },
  }
}
</script>

<style scoped>
.section-box {
  position: relative;
  background-color: #FFFFFF;
  padding: 0 20px;
  font-size: .8rem
}

.section-title {
  display: flex;
  font-weight: 600;
  font-size: .9rem;
}

.title-tag {
  width: 5px;
  height: 20px;
  border-radius: 10px;
  background-color: #259b43;
  margin-right: 10px;
}

.red-btn {
  background-color: red;
  color: #FFFFFF;
  padding: .3rem 1rem;
  border-radius: .5rem;
  font-size: .5rem;
}

.team-item {
  background-color: #dbdedd;
  color: red;
  padding: .5rem 1rem;
  border-radius: 5px;
}
</style>